$charlestonGray: #121F29;
$darkGrey: #222B34;
$gunmetal: #363D45;
$darkGunmetal: #2A323A;
$blueSmoke: #656F78;
$stormGray: #888D95;
$lightSteelBlue: #AAAEB1;
$silverChalice: #CACBCD;
$lightGray: #E5E5E5;
$whiteSmoke: #F2F2F2;
$white: #FFFFFF;
$dodgerBlue: #4471FF;
$cornflowerBlue: #4F80FE;
$skyBlue: #3D65E5;
$royalBlue: #5F86FF;
$darkBlue: #262E37;
$red: #E84A4D;
$yellow: #F8DC00;
$green: #44D13F;
$emeraldGreen: #0FC949;
$limeGreen: #45FF1B;
$darkOrange: #FF8C14;
$cyan: #1BFFEF;
$electricPink: #F01DF1;
$mediumPurple: #8759FF;

$primary-color: $dodgerBlue;
$table-font-color: $lightGray;
$color-text: $stormGray;

//heading
$font-weight-heading: 600;
$line-height-heading: 1.2;
$margin-bottom-heading: 10px;

$opacity100: 1;
$opacity95: 0.95;
$opacity80: 0.8;
$opacity70: 0.7;
$opacity60: 0.6;
$opacity40: 0.4;
$opacity15: 0.15;
$opacity25: 0.25;
$opacity10: 0.10;
$opacity6: 0.06;
$opacity0: 0;

$transparent: transparent;