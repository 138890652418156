@import 'scss/vendors/include-media';

.editable-tag-group {

  .ant-btn {

    @include media('<tablet') {
      transform: translateY(-10px);
    }
  }

  .edit-tag {
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    height: 32px;
    padding: 0 11px;
    margin: 0;
    border: 1px solid var(--blueSmoke);

    &:hover {
      border-color: var(--lightSteelBlue);
    }


    .ant-tag-close-icon {
      color: var(--blueSmoke);
      font-size: 16px;

      &:hover {
        color: var(--lightSteelBlue);
      }
    }
  }
}